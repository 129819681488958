<template lang="pug">
bl-section.bukamall-section(v-if="!isLoading")
  bl-container
    bl-section-header(
      headingText="Keliling mall tanpa kaki pegal"
      :taglineList="taglines"
    )

    bl-card(:isOutlined="true" :borderRadius="8")
      bl-flex-container(justifyContent="space-between")
        bl-flex-item(:grow="0" basis="25%" :class="isSupportWebp() ? 'bukamall-cover--webp': 'bukamall-cover'")
          bl-flex-container(
            justifyContent="center"
            alignItems="center"
            style="height: 100%; background: rgba(0, 0, 0, 0.25)"
            direction="column"
          )

            bl-image-av(:src="`https://s1.bukalapak.com/pavilion/1586920137167/original/bukamall-logo-white.png`" height="52px" width="172px" img-class="bukamall-logo")
            bl-text.mt-20(:isInverse="true" align="center" style="max-width: 280px")
              | Di sini kamu bisa lihat-lihat dan beli barang asli dari berbagai brand resmi
            bl-button.mt-16(
              href="https://www.bukalapak.com/bukamall/?from=brand-homepage"
              data-test="bukamall-section-mall-btn"
            )
              | Yuk, keliling Mall!

        bl-flex-item(basis="75%" style="max-width: 75%")
          bl-carousel(
            :key="renderKey"
            border-radius="0 8px 8px 0"
            :gutter="0"
            :item-per-slide="5")
            bl-carousel-slide(v-for="(store, index) in bukaMallStores" :key="store.id")
              bl-highlighted-brand(
                data-test="bukamall-section-highlighted-brand"
                :brandName="store.name"
                :brandLogo="optimizeStoreLogo(store.logo_url)"
                :brandUrl="`${store.url}?from=brand-homepage-${(index + 1)}`"
                :productAlt="store.description"
                :productImg="store.background_image.desktop_url ? optimizeStoreBg(store.background_image.desktop_url) : assetUrl('white-bg.png')"
              )

bl-section.bukamall-section(v-else data-test="shimmer-container")
  bl-container
    bl-shimmer.mb-20(width="298px" height="36px" :borderRadius="4")
    bl-shimmer.mb-20(width="100%" height="382px" :borderRadius="8")
</template>

<script>
import BlSection from '@bukalapak/bazaar-dweb/dist/components/BlSectionMv';
import BlContainer from '@bukalapak/bazaar-dweb/dist/components/BlContainerMv';
import BlCard from '@bukalapak/bazaar-dweb/dist/components/BlCardMv';
import BlFlexContainer from '@bukalapak/bazaar-dweb/dist/components/BlFlexContainerMv';
import BlFlexItem from '@bukalapak/bazaar-dweb/dist/components/BlFlexItemAv';
import BlText from '@bukalapak/bazaar-dweb/dist/components/BlTextAv';
import BlSectionHeader from '@bukalapak/bazaar-dweb/dist/components/BlSectionHeaderMv';
import BlButton from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';
import BlCarousel from '@bukalapak/bazaar-dweb/dist/components/BlCarouselMv';
import BlCarouselSlide from '@bukalapak/bazaar-dweb/dist/components/BlCarouselSlideMv';
import BlHighlightedBrand from '@bukalapak/bazaar-guild-dweb/dist/components/BlHighlightedBrandMv';
import BlShimmer from '@bukalapak/bazaar-dweb/dist/components/BlShimmerAv';
import BlImageAv from '@bukalapak/bazaar-visual-v1/dist/components/BlImageAv';
import { optimizeWithSize, supportWebp } from 'shared_js/helpers/imagery-optimization';

const BANNER_SIZE = 'w-152';
const STORE_BG_SIZE = 'w-250';
const resizeLogoFn = optimizeWithSize(BANNER_SIZE);
const resizeStoreBgFn = optimizeWithSize(STORE_BG_SIZE);

const STATE = {
  init: 'INIT',
  loading: 'IS_LOADING',
  failure: 'IS_FAILURE',
  success: 'IS_SUCCESS',
};

export default {
  components: {
    BlSection,
    BlContainer,
    BlCard,
    BlText,
    BlButton,
    BlFlexContainer,
    BlFlexItem,
    BlSectionHeader,
    BlCarousel,
    BlCarouselSlide,
    BlHighlightedBrand,
    BlShimmer,
    BlImageAv,
  },
  data() {
    return {
      renderKey: 'init',
      taglines: [
        {
          id: 1,
          item: '100% Genuine',
        },
        {
          id: 2,
          item: 'Official Store',
        },
        {
          id: 3,
          item: '14 Days Return Guarantee'
        }
      ],
    }
  },
  computed: {
    sectionState() {
      return this.$store.state.bukaMallSection.section;
    },
    bukaMallStores() {
      return this.$store.state.bukaMallSection.bukaMallStores;
    },
    isLoading() {
      return this.sectionState === STATE.loading;
    },
    isInit() {
      return this.sectionState === STATE.init;
    },
  },
  mounted() {
    if (this.sectionState === STATE.init) {
      this.$store.dispatch('bukaMallSection/setupBukaMallSection')
    }
  },
  methods: {
    assetUrl: global.assetUrl,
    optimizeStoreLogo(imageUrl) {
      return resizeLogoFn(imageUrl);
    },
    optimizeStoreBg(imageUrl) {
      return resizeStoreBgFn(imageUrl);
    },
    isSupportWebp() {
      return supportWebp()
    }
  }
}
</script>

<style>
.bukamall-cover {
  background: url('bukamall-banner.jpg') 50% 50%;
  border-radius: 8px 0 0 8px;
  width: 100%;
  background-size: cover;
}

.bukamall-cover--webp {
  background: url('bukamall-banner.webp') 50% 50%;
  border-radius: 8px 0 0 8px;
  width: 100%;
  background-size: cover;
}
</style>
